<template>
  <div>
    <dossier-info-header-card class="no-float margin" :dossier-info="headerCardInfo" />
    <crud-editor
      ref="editor"
      :is-edit="isEdit"
      :definition="definition"
      :fetch-by-id="fetchById"
      :disabled="hasNoEditPermission"
      :save="saveData"
      :return-to="returnTo"
      :item-type="entity"
      :page-loading="loading"
      :get-dependencies="getDependencies"
    />
  </div>
</template>

<script>
import CrudEditor from '@/components/crud/Editor';
import formMixin from './form-mixin';
import { hasEditPermissionForCode } from '@/utils/permission';
import DossierInfoHeaderCard from '@/pages/dossiers/DossierInfoHeaderCard';

export default {
  components: {
    CrudEditor,
    DossierInfoHeaderCard
  },
  mixins: [formMixin],
  data() {
    return {
      isEdit: true,
      dossier: {
        dossierID: this.$route.params.dossierID
      },
      headerCardInfo: {}
    };
  },
  computed: {
    hasNoEditPermission() {
      return !hasEditPermissionForCode(this.entity, this.$store.getters.permissions);
    },
    loading() {
      return this.$store.getters[this.entityStore + '/isLoading'];
    }
  },
  methods: {
    async fetchById(id) {
      const action = await this.$store.dispatch(this.entityStore + '/getItem', {
        selector: { id }
      });

      const dossier = await this.$store.dispatch('dossier/getItem', {
        selector: { id: action.dossierID }
      });

      this.headerCardInfo = dossier;

      return this.createFormDataFromItem(this, action);
    },
    saveData(form) {
      const item = this.createItemFromFormData(this, form);
      return this.$store.dispatch(this.entityStore + '/persistItem', item);
    }
  }
};
</script>

<style scoped>
.no-float {
  float: none;
}

.margin {
  margin: 10px;
}
</style>
